<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <br />
        <v-dialog persistent v-model="isLoading">
            <v-row justify="center">
                <v-card width="400">
                    <center class="pa-5">
                        Importing Course data.<br />Please Wait It will Take Time..
                    </center>
                    <div class="ma-6">
                        <v-progress-linear v-model="percent" color="amber" height="25">
                            <template v-slot:default="{ value }">
                                <strong>{{ Math.ceil(value) }}%</strong>
                            </template>
                        </v-progress-linear>
                    </div>
                </v-card>
            </v-row>
        </v-dialog>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br>
        <br />

        
        <v-card class="card">
            <v-card-title class="heading">Import Course</v-card-title>
            <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
            <div class="add-section">
                <v-row class="justify-content-between set-btn">
                    <div><a href="https://library-public.s3.ap-south-1.amazonaws.com/sample_csv/library_import_course.xlsx">
                            <v-btn class="mb-2 button"> <v-icon left dark>mdi-cloud-download</v-icon>Export XLSX
                                Template</v-btn></a>
                    </div>
                    <div>
                        <v-btn class="button mb-2" @click="addCourse"> <v-icon left>mdi-account-plus</v-icon>Add New
                            Course</v-btn>
                    </div>
                </v-row>
                <p class="file-warn">NOTE: *File should be in XLSX format.</p>

                <v-row class="justify-content-center">
                    <v-col lg="5" sm="12" md="5" class="import-box">
                        <label class="label">IMPORT New Course XLSX</label>
                        <v-file-input dense v-model="file" :hint="file != null ? '' : 'Please Select File'" persistent-hint
                            color="rgb(1, 127, 152)" label="Choose File" accept=".csv" prepend-icon="mdi-paperclip" outlined
                            show-size>
                        </v-file-input>
                        <v-btn @click="importCsv" :loading="output_load" class="button">
                            Import Course
                            <v-icon right>mdi-cloud-upload</v-icon>
                        </v-btn>
                    </v-col>

                    <v-col lg="5" sm="12" md="5" class="import-box">
                        <label class="label">UPDATE Existing Course XLSX</label>
                        <v-file-input dense v-model="fileupdate" :hint="fileupdate != null ? '' : 'Please Select File'"
                            persistent-hint color="rgb(1, 127, 152)" label="Choose File" accept=".csv"
                            prepend-icon="mdi-paperclip" outlined show-size>
                        </v-file-input>
                        <v-btn @click="importCsvUpdate" :loading="output_load_update" class="button">
                            Update Course
                            <v-icon right>mdi-cloud-upload</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
</div>
            <template>
                <h5 v-if="failList.length > 0" class="mt-4" style="text-align: center; color: red;">Failed Record</h5>
                <v-simple-table v-if="failList.length > 0">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Excel row no</th>
                                <th>Error</th>
                                <th>Course Name</th>
                                <th>Course Code</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in failList" :key="item.name" style="color:red;">
                                <th>{{ item.rowno }} </th>
                                <th>{{ item.error }} </th>
                                <th>{{ item.row.course_name }} </th>
                                <th>{{ item.row.course_code }} </th>

                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>

            <template>
                <h5 v-if="successList.length > 0" class="mt-4" style="text-align: center; color: green;">Success Record</h5>
                <v-simple-table v-if="successList.length > 0">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Excel row no</th>
                                <th>Course Name</th>
                                <th>Course Code</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in successList" :key="item.name" style="color:green;">
                                <th>{{ item.rowno }} </th>
                                <th>{{ item.row.course_name }} </th>
                                <th>{{ item.row.course_code }} </th>

                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>

            <div v-if="loader">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
            <template v-if="courselist != null">
                <v-data-table :headers="headers" :items="courselist" :search="search" class="elevation-1 nowrap">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line
                                hide-details></v-text-field>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.edit="{ item }">
                        <v-icon v-if="item" class="mr-2" @click="editItem(item)">mdi mdi-pencil-box</v-icon>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                        <v-switch v-model="item.courseIsactive" @change="editItemm(item)"></v-switch>
                    </template>
                    <template v-slot:item.delete="{ item }">
                        <v-icon v-if="item" class="mr-2" @click="deletecourse(item)"> mdi mdi-delete</v-icon>
                    </template>
                </v-data-table>
            </template>
            </div>

            <v-dialog v-model="course_dialog" width="500">
                <v-card>
                    <v-card-title class="heading">
                        <v-row>
                            <v-col cols="11" md="11" sm="11">
                                {{ formTitle }}
                            </v-col>
                            <v-col cols="1" md="1" sm="1">
                                <v-btn icon dark @click="close">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>

                                <v-col cols="12">
                                    <label class="add-text">Course Code</label><span style="color: red;"> *</span>
                                    <v-text-field dense outlined v-model="editedItem.course_code"
                                        item-text="roleLink_list_id" item-value="id"
                                        :hint="editedItem.course_name != null ? '' : 'Please enter Bill No.'"
                                        persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <label class="add-text">Course Name</label><span
                                        style="color: red; font-weight: bolder"> *</span>
                                    <v-text-field dense outlined v-model="editedItem.course_name"
                                        item-text="roleLink_list_id" item-value="id"
                                        :hint="editedItem.course_name != null ? '' : 'Please enter Bill No.'"
                                        persistent-hint></v-text-field>
                                </v-col>



                            </v-row>
                        </v-container>
                        <small style="color: red;">*indicates required field</small>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="button-cancel" @click="course_dialog = false">Close</v-btn>
                        <v-btn class="button" text @click="save">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


        </v-card>
    </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css";
export default {
    data() {
        return {
            init_loading: false,
            loader: false,
            snackbar_msg: "",
            snackbar: false,
            color: "",
            dialog: false,
            search: "",
            valid: false,
            items: [
                {
                    text: 'Dashboard',
                    disabled: false,
                    href: '/home',
                },
                {
                    text: localStorage.getItem("jhhjbyj"),
                    disabled: false,
                    href: '/sublink',
                },
                {
                    text: 'Admin Master',
                    disabled: false,
                    href: '/admin-master',
                },
                {
                    text: 'Course',
                    disabled: true,
                    href: '',
                },
            ],
            course_dialog: false,
            courselist: [],

            showPickerPurchase: false,
            showPickerPO: false,
            editedItem: {
                course_name: "",
                course_code: "",
                courseId: "",
            },

            courselist: [],
            headers: [
                { text: "Sr no", value: "srno" },
                { text: "Course Code", value: "course_code" },
                { text: "Course Name", value: "course_name" },
                { text: "Edit", value: "edit" },
                { text: "Toggle", value: "actions", sortable: false },
                { text: "Delete", value: "delete" },
            ],

            editedIndex: -1,
            file: null,
            fileupdate: null,
            isLoading: false,
            failList: [],
            successList: [],
            output_load: false,
            output_load_update: false,
            fileStatus: true,
            fileStatusupdate: true,
            count: -1,
            countupdate: -1,
            percent: 0,
            percentupdate: 0,
        };
    },

    computed: {
        getEndDate() {
            var endDate = new Date();
            return endDate.toISOString().slice(0, 10)
        },
        formTitle() {
            return this.editedIndex === -1 ? "New Course" : "Edit Course";
        },
    },

    mounted() {
        this.onLoad();
    },

    methods: {
        addCourse() {
            this.course_dialog = true;
            this.editedIndex = -1;
        },

        editItem(item) {
            this.editedIndex = this.courselist.indexOf(item);
            this.editedItem = Object.assign({}, item);

            this.course_dialog = true;
        },
        editItemm(item) {
            console.log(item);
            axios
                .post("/admin/courseIsactive", item)
                .then(() => {
                    // this.sec_mode_list = res.data.user_list;
                    this.showSnackbar("#4CAF50", " Course updated successfully...");
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.edit_btn_load = false;
                    axios;
                });
        },
        onLoad() {
            this.init_loading = true
            axios
                .post("/admin/getCourse")
                .then((res) => {
                    this.courselist = res.data.course_list;
                    this.init_loading = false
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.init_loading = false
                });
        },

        save() {
            this.editedItem.course_name = this.editedItem.course_name.trim();
            if (this.editedItem.course_code == null || this.editedItem.course_code == "") {
                this.showSnackbar("#b71c1c", "Please Enter Course Code !!!");
            }
            else if (this.editedItem.course_name == null || this.editedItem.course_name == "") {
                this.showSnackbar("#b71c1c", "Please Enter Course Name. !!!");
            } else {
                if (this.editedIndex > -1) {
                    axios
                        .post("/admin/editCourse", this.editedItem)
                        .then((res) => {
                            if (res.data.msg == "success") {
                                this.loader = false;
                                this.showSnackbar("#4caf50", "Course Updated Successfully...");
                            } else {
                                this.loader = false;
                                this.showSnackbar("#b71c1c", res.data.msg);
                            }
                            this.onLoad();
                        })
                        .catch((error) => {
                            this.overlay = false;
                        });
                    this.close();
                } else {
                    this.loader = true;
                    axios
                        .post("/admin/addCourse", this.editedItem)
                        .then((res) => {
                            if (res.data.msg == "success") {
                                this.loader = false;
                                this.showSnackbar("green", "Course Added Successfully.");
                                this.onLoad();
                                this.close();
                            } else {
                                this.loader = false;
                                this.showSnackbar("#b71c1c", res.data.msg);
                            }
                        }).catch((error) => {
                            this.loader = false;
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        }).finally(() => {
                            this.loader = false;
                        });
                }
            }
        },



        close() {
            this.course_dialog = false;
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
        },

        deletecourse(item) {
            if (confirm("Are You Sure You Want To Delete  Course?")) {
                const data = {
                    courseId: item.courseId,

                };
                axios
                    .post("/admin/deleteCourse", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.showSnackbar("#4CAF50", "Course Deleted Successfully  !!");
                            this.onLoad();
                        } else if (res.data.msg == "exp") {
                            this.showSnackbar("#b71c1c", "Can not be Deleted, Already In Use!!!");

                        }
                    }).catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        window.console.log(error);
                    });
            }
        },

        fileuploading() {
            alert("in file uploading")
            let formData = new FormData();
            this.fileStatus = true;
            formData.append("file", this.file);
            formData.append("count", this.count);
            axios
                .post("ImportData/importCourse", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    if (res.data.status == "200") {
                        if (this.count != -1) {
                            for (var i = 0; i < res.data.successList.length; i++) {
                                this.successList.push(res.data.successList[i]);
                            }
                            for (var i = 0; i < res.data.failList.length; i++) {
                                this.failList.push(res.data.failList[i]);
                            }
                        }
                        this.count = res.data.count;
                        this.totalcoursesize = res.data.totalcoursesize;
                        this.percent = (this.count / this.totalcoursesize) * 100;
                        if (res.data.isdone) {
                            this.count = -1;
                            this.isLoading = false;
                            this.showSnackbar("#4caf50", "Course added successfully...");
                            this.onLoad();
                        } else {
                            this.fileuploading();
                        }
                    }
                })

        },

        importCsv() {

            this.failList = [];
            this.successList = [];
            if (this.file !== null) {
                this.isLoading = true;
                if (!(this.file.name.toLowerCase().includes(".xlsx") || this.file.name.toLowerCase().includes(".XLSX"))) {
                    this.showSnackbar("red", "File must be in xlsx format Only.!");
                } else {
                    this.fileuploading();
                }
            } else {
                this.fileStatus = false;
                this.showSnackbar("#b71c1c", "Please select file");
            }
        },


        fileuploadingUpdate() {
            let formData = new FormData();
            this.fileStatusupdate = true;
            formData.append("fileupdate", this.fileupdate);
            formData.append("countupdate", this.countupdate);
            axios
                .post("ImportData/importExistingBill", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    if (res.data.status == "200") {
                        if (this.countupdate != -1) {
                            for (var i = 0; i < res.data.successList.length; i++) {
                                this.successList.push(res.data.successList[i]);
                            }
                            for (var i = 0; i < res.data.failList.length; i++) {
                                this.failList.push(res.data.failList[i]);
                            }
                        }
                        this.countupdate = res.data.count;
                        this.totalcoursesizeupdate = res.data.totalcoursesize;
                        this.percentupdate = (this.count / this.totalcoursesizeupdate) * 100;
                        if (res.data.isdone) {
                            this.countupdate = -1;
                            this.isLoading = false;
                            this.showSnackbar("#4caf50", "Course Updated successfully...");
                            this.onLoad();
                        } else {
                            this.fileuploadingUpdate();
                        }
                    }
                })

        },

        importCsvUpdate() {
            if (confirm("Are You Sure You Want To Update Bill Details?")) {
                this.failList = [];
                this.successList = [];
                if (this.fileupdate !== null) {
                    this.isLoading = true;
                    if (!(this.fileupdate.name.toLowerCase().includes(".xlsx") || this.fileupdate.name.toLowerCase().includes(".XLSX"))) {
                        this.showSnackbar("red", "File must be in xlsx format Only.!");
                    } else {
                        this.fileuploadingUpdate();
                    }
                } else {
                    this.fileStatusupdate = false;
                    this.showSnackbar("#b71c1c", "Please select file");
                }
            }
        },

        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },




    },
};
</script>

<style scoped>
.downbtn {
    float: right;
}

.add-section {
    display: block;
}

.box {
    background-color: #add8e630;
    margin: 8px !important;
    padding: 8px;
}

.flex {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 600px) {
    .flex {
        display: block;
    }
}

.btn {
    margin: 1rem;
    padding-top: 1rem;
}
</style>
